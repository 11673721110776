<template>
  <ApolloQuery
    :query="require('../graphql/queries/getAdminProfile.gql')"
    class=""
  >
    <template v-slot="{ result: { error, data }, isLoading }">
      <!-- Loading -->
      <div
        v-if="isLoading === 1"
        class="mx-auto"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>

      <!-- Error -->
      <AppNotification
        v-else-if="error"
        :message="error.message"
      />

      <!-- Result -->
      <template v-else-if="data">
        <v-col
          v-if="onlyOwner ? data.getAdminProfile.role !== 'SUPERUSER': !(data.getAdminProfile.permissions.filter(value => permissions.includes(value))).length && !data.getAdminProfile.permissions.includes(permission) && data.getAdminProfile.role !== 'SUPERUSER'"
        >
          <v-alert
            outlined
            type="error"
          >
            Этот раздел вам недоступен!
          </v-alert>
        </v-col>

        <slot
          v-else
          :profile="data.getAdminProfile"
        />
      </template>

      <!-- No result -->
      <AppNotification
        v-else
        message="Нет результата :("
      />
    </template>
  </ApolloQuery>
</template>

<script>
import AppNotification from '@/components/AppNotification.vue';

export default {
  components: {
    AppNotification,
  },
  props: {
    permission: {
      type: String,
      default: '',
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    onlyOwner: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
